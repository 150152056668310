import React from 'react';

export const PhongNha = {
    "name": "Phong Nha",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50380480076_09dc09c11e_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380480076_3478a0a2e9_o.jpg",
    "caption": "Outdoor Activites and Incredible Caves",

    "brief": "Phong Nha-Ke Bang National Park, designated a UNESCO World Heritage Site in 2003, comprises a large collection of caves and karst mountains. Contained in its 400 million years of history are some of the largest caves in the world, some with spectacular underground rivers and even their own ecosystems. Phong Nha is a paradise for anyone looking for a good adventure.",

    "description": <p>
            Located in the lush jungles of Central Vietnam, this UNESCO heritage site is characterized by it's mountains, tropical forests and underground caves, containing the oldest karst mountains in Asia and the biggest underground cave in the world, Hang Sơn Đoòng cave, discovered recently in 2009.<br/><br/>
        Considered the adventure capital of Vietnam, this national park offers a range of activities, from trekking its dense jungle and exploring the many caves, to cycling and motorbiking around the park, kayaking, swimming, camping and much more. Phong Nha has a lot to offer for thrill seekers of any age, and boost some of the best natural scenery you will find in Southeast Asia.<br/><br/>
    Although Phong Nha is changing fast with the recent boom is tourism, the region is still somewhat lacking in infrastructures compared to other places in Vietnam, and you will find here a more natural side of life in Vietnam. This is due to change in the future, as more and more tourists discover this magnificent place.<br/><br/>
Of course, the caves are the highlights here, and you can either explore them alone or with an organized excursion. The most famous caves you can visit on your own are <b>Phong Nha Cave</b>, <b>Dark Cave</b> and <b>Paradise Cave</b>. On the other hand, going with an organized excursion will allow you to explore further inside the caves, with climbing and swimming included for some excursions. There are also multi-day excursions where you will be sleeping inside the caves, and if you have $3000 to spare and are looking for the adventure of a lifetime, you can take the 4 day expedition to Hang Sơn Đoòng cave, the largest cave in the world. This and other excursions can be booked online with <a href="https://oxalisadventure.com/" target="_blank" rel="noopener noreferrer">Oxalis Adventure</a>, one of the local companies authorized to operate tours around the caves.
            </p>,

    "nrDays": "If you are only interested on doing activities on your own, 2 days should be enough to visit the main caves. If you are planning on doing an organized excursion, you will need to factor that time also. You can also spend one or two more days here to just relax and explore some rural areas",

    "whenVisit": "You should definitely avoid the raining season (from September to December), where many of the caves will be closed. Visiting at other times of the year is a must.",

    "getHere": <p>
            There are two main options to reach Phong Nha, bus and train. They both take approximately the same time, and whilst the train is more comfortable, it is more expensive and the nearest stop isn't exactly in Phong Nha, but instead in Dong Hoi, 45 kms apart. You will need to get a Grab or ride another bus to reach Phong Nha.
            <br/><br/>
            The bus is the better option here, and you have connections from both <a href="/travel-guides/asia/vietnam/hue" >Hue</a> and <a href="/travel-guides/asia/vietnam/hanoi">Hanoi</a> (you can also find a connection with <a href="/travel-guides/asia/vietnam/ninh-binh">Ninh Binh</a>). The journey from Hue takes around 4 hours, and from Hanoi will take approximately 10 hours. For this you will preferably want to catch a night bus, since you won't lose time and save costs on accommodation.
            <br/><br/>
            You can find the schedule for the buses on <a href="https://www.thesinhtourist.vn/busschedule" target="_blank" rel="noopener noreferrer">The Sinh Tourist</a>, and also on <a href="http://www.mailinhexpress.vn/" target="_blank" rel="noopener noreferrer">Mai Linh Express</a> and on <a href="https://north-vietnam.com/vietnam-bus-schedule-timetable-bus-tickets/" target="_blank" rel="noopener noreferrer">Northern Vietnam</a>.
        </p>,

    "activities": {
        "description": "As mentioned before, the main attractions of Phong Nha are the caves, that you can explore on your own or with an organized excursion. You can also rent a scooter or a bike (your hostel will usually have them for rent) and drive around the park for some amazing scenery. Below we give some suggestions for things you can do.",
        "activities": [
            {
                "name": "Phong Nha Cave",
                "image": "https://live.staticflickr.com/65535/50505172792_aa66881d78_o.jpg",
                "description":
                    <div>
                        <ul>
                              <li><b>Entry Fee:</b> $7</li>
                              <li><b>Average Duration:</b> 3 hours</li>
                        </ul>
                        <p>
                            The first cave to be opened to the public, and from which the park gets the name. The cave is accessed by boat, and the departure point is near the river on the center of the city, where you can buy tickets for the cave.
                            The boat will take you along the underground river to inside the cave, where you will be able to walk around the ancient rock formations and explore the cave.
                        </p>
                    </div>
            },
            {
                "name": "Paradise Cave",
                "image": "https://live.staticflickr.com/65535/50505013201_c93d52ef3e_o.jpg",
                "description":
                    <div>
                        <ul>
                              <li><b>Entry Fee:</b> $12</li>
                              <li><b>Average Duration:</b> 2 hours</li>
                        </ul>
                        <p>
                            Open to the public in 2010, it is considered the most beautiful cave in Phong Nha, and the one you must visit if you short on time. Although the cave is large, only the first kilometer is open to the public (you can explore further with an organized excursion).
                            You will be surrounded by multi-colored stalagmites as you walk around the cave, creating a unique scenery you can't find anywhere else.
                        </p>
                    </div>
            },
            {
                "name": "Dark Cave",
                "image": "https://live.staticflickr.com/65535/50505013176_f1d742ef7a_o.jpg",
                "description":
                    <div>
                        <ul>
                              <li><b>Entry Fee:</b> $20</li>
                              <li><b>Average Duration:</b> 2-3 hours</li>
                        </ul>
                        <p>
                            One of the few caves that have no artificial lightning installed, making justice to it's name. This is a cave for the adventurous, and you if are heading here alone, you will to wait in line for a group to form. Be warned that to go inside the cave you need to be dressed in a swimsuit (which you can also buy at the entrance).
                            Inside, you will have to wear an helmet with headlamp and a life vest, and if you are adventurous enough, you can even swim in the river inside. In the end, you will take on a zipline to exit the cave.
                            The entrance to the Dark Cave is situated on the Abandoned Valley, a few kilometers away from the city center.
                        </p>
                    </div>
            },
            {
                "name": "Elephant Cave",
                "image": "https://live.staticflickr.com/65535/50505013156_8241962f98_o.jpg",
                "description":
                    <div>
                        <ul>
                              <li><b>Entry Fee:</b> $10</li>
                              <li><b>Average Duration:</b> 1-2 hours</li>
                        </ul>
                        <p>
                            The cave is named after the elephant shaped stalactites at the entrance of it. This is a very picturesque cave, with sunlight penetrating through the rocks in the ceiling and illuminating a jungle inside the cave.
                            Further exploration of this cave can be done through a 1 day organized excursion.
                        </p>
                    </div>
            },
            {
                "name": "Hang Son Doong",
                "image": "https://live.staticflickr.com/65535/50505013141_c6208c85f2_o.jpg",
                "description":
                    <div>
                        <ul>
                              <li><b>Entry Fee:</b> $3000</li>
                              <li><b>Average Duration:</b> 4 days</li>
                        </ul>
                        <p>
                            The majestic Hang Son Doong, the biggest cave in the world, it could hold an entire city block of Manhattan inside of it, including the highest skyscrapers.
                            Truly an adventure you will never forget, this cave can only be accessed through an organized tour operated by  <a href="https://oxalisadventure.com/" target="_blank" rel="noopener noreferrer">Oxalis Adventure</a>.
                            Currently, this tour costs around $3000 and takes 4 days, where you will sleep, eat, and do your everyday activities inside the cave. There is no other way to visit this cave, and the waitlist for the tour is usually of 1-2 years.
                        </p>
                    </div>
            },
            {
                "name": "Botanical Garden",
                "image": "https://live.staticflickr.com/65535/50505172702_4f1dc0931c_o.jpg",
                "description": "Pick up a scooter or a bike, and a few kilometers away from central Phong Nha you will reach the Botanical Garden. There, you will find a small lagoon with a waterfall, where you can go for a swim."
            }
        ]
    },

    "food": {
        "description": "Despite a relative lacking in infrastructures, Phong Nha offers some interesting places where you can stop by on during your adventures to have something to eat or drink.",

        "restaurants": [
            {
                "name": "The Pub With The Cold Beer",
                "description": "Like the name suggest, this is the most famous place to grab a beer, and a cold one. This place is somewhat of a must visit for travelers passing through Phong Nha. Everything they serve here is grown on their own property.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50504298933_ffff4bae01_o.jpg"
            },
            {
                "name": "The Duck Stop",
                "description": "Another famous place around Phong Nha, After having a drink and a snack here, you have the chance to feed and play with ducks. You can also pay to throw a duck, and it is a sign of good luck. There is even a chance to ride the water buffalo named Donald Trump.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50504298918_bccfc544c2_o.jpg"
            }
        ]
    },

    "stay": {
        "description": "With the recent boom in tourism, an increase number in guesthouses and homestays are appearing around town.",

        "accomodations": [
            {
                "name": "Easy Tiger Hostel",
                "image": "https://live.staticflickr.com/65535/50505172632_47d1d6a83b_o.jpg",
                "description": "This is considered the place to STAY in Phong Nha, and it's easy to understand why. It's a lively, energetic and social place where travelers from all over the world meet and share their stories. It offers a range of activities you can take part in, from international food tours, happy hours, a chill-out area, pool table, swimming pool and many others. This is an incredible hostel, perhaps the best in Vietnam. On the bad side, it's almost always full, so make sure to book ahead.",
                "link": ""
            },
            {
                "name": "Gecko Backpackers Hostel",
                "image": "https://live.staticflickr.com/65535/50505013061_177de438df_o.jpg",
                "description": "Other very good hostel on Phong Nha, it's usually the place backpackers go to whenever Easy Tiger is full. Still, it's a very good hostel on its own. The dorms are cheap and clean, and it also has a bar, a pool and cosy spaces to meet other travelers.",
                "link": ""
            },
            {
                "name": "Phong Nha Farmstay",
                "image": "https://live.staticflickr.com/65535/50504298883_cddeac304c_o.jpg",
                "description": "This is the place that helped put Phonh Nha on the map. Located 10 km away from town, this is an attractive accommodation surrounded by rice paddles. It's a place with a great ambience, where you will feel welcomed.",
                "link": ""
            },
            {
                "name": "Victory Road Villas",
                "image": "https://live.staticflickr.com/65535/50505172592_2c39984c50_o.jpg",
                "description": "This is the most luxurious accommodation in Phong Nha. It has a handful of rooms, all of which are duplexes carefully decorated. The exterior pool is the biggest in Phong Nha, and has some excellent vistas over the river are mountains of the park.",
                "link": ""
            }
        ]
    }
}
